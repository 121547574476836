import React from "react"
import { Container } from "react-bootstrap"
import GGFXImage from "../../modules/GGFXImage"
import ScrollAnimation from "react-animate-on-scroll"
import CustomSlider from "../CustomSlider/CustomSlider"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./FeatureDeveloper.scss"
import { Link } from "gatsby"
import { offplanURL } from "../../lib/urls"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const FeatureDeveloper = ({featureDeveloperData, moduleData, pageData }) => {

  const { isMobile } = useDeviceMedia()

  const imagename = "page.item_image.feature_developer"
  return (
    <div className="feature-developer-wrapper">
      <Container>
        <div className="feature-developer-list">
          <h2>{moduleData?.title}</h2>
          <div className="developer-list-feature">
            <CustomSlider
              noDots
              showArrows
              iconchange
              slidecount={isMobile ? 1.2 : 3}
              className="feature-developer-slider"
            >
              {featureDeveloperData?.length > 0 &&
              featureDeveloperData?.map((item, index) => {
                  return (
                    <ScrollAnimation
                      className="offplan-feature-item"
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={index * 100}
                      offset={100}
                    >
                      <Link to={`${item?.node?.slug}/`}>  
                      <div className="image-section">
                        <GGFXImage
                          ImageSrc={item?.node?.developer_banner?.image}
                          altText={
                           item?.node?.developer_name
                          }
                          imagetransforms={pageData?.ggfx_results}
                          renderer="srcSet"
                          imagename={imagename}
                          strapiID={pageData?.strapi_id}
                        />
                        <div className="developer-image">
                            <img src={item?.node?.developer_image?.url} alt="developer logo"/>
                        </div>
                      </div>
                      </Link>
                      <div className="developer-content-section">
                      <Link to={`${item?.node?.slug}/`}>  
                        <h3>{item?.node?.developer_name}</h3>
                        </Link>
                        <p className="description">
                          <ContentModule
                            Content={item?.node?.developer_banner?.banner_content?.data?.banner_content}
                          />
                        </p>
                      </div>
                    </ScrollAnimation>
                  )
                })}
            </CustomSlider>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FeatureDeveloper
