import React from "react"
import { Accordion } from "react-bootstrap"
import { Container, Row, Col } from "react-bootstrap"
import "./Accordion.scss"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const Accordian = (props) => {
  return (
    <div className={`accordion-wrapper ${props.isChangeBg?"white-bg":""}`}>
      <Container>
        <Row>
          <Col xl={12}>
          <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={200} offset={100}>

            <h2 className="big-title">{props?.title}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={250} offset={100}>
            <Accordion className="accordion">
              {props?.add_toggle?.map((item, index) => (

                <Accordion.Item eventKey={index} key={item?.title}>
                  <Accordion.Header>{item?.title}</Accordion.Header>
                  <Accordion.Body>
                    {(item.content?.data?.content || (item?.content &&props.isPreviewEnabled)) && (
                      <ContentModule Content={item.content?.data?.content || item.content} />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                
              ))}
            </Accordion>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Accordian
