/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
const { useLocation } = require("@reach/router")


function Seo({ description, title, imageUrl, children,isPreview,height,width,isOffplan,status,isNoIndexPage }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            env
          }
        }
      }
    `
  )

 
  const previewValue = typeof window !=="undefined"? window.location.pathname?.includes("preview-") : false

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title?site.siteMetadata?.title:"haus & haus"

  let robots = status === "Off Market" || isNoIndexPage || previewValue ? "noindex" : "all"
  if(process.env.GATSBY_SITE_ENV !== "production"||isPreview){
    robots = "none"
  }

  var { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home") pathname = "/"

  var currentUrl = ""
  currentUrl = process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!currentUrl.endsWith("/")) currentUrl = currentUrl + "/"

  // remove page- from url
  if (currentUrl.includes("page-")) {
    currentUrl = currentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }

  //remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  currentUrl = currentUrl.toLowerCase()

  const lastIndex = title?.lastIndexOf(" |");

const result = lastIndex !== -1 ? title?.substring(0, lastIndex) : title;

  const ogTitle = result?.replace(/ \|$/, '');

  return (
    <>
      <Helmet>
      <title>{isOffplan?title:defaultTitle ? `${title} ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={`${ogTitle} | haus & haus`} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />

      <meta name="image" property="og:image" content={imageUrl ? imageUrl : ""} />
      <meta property="og:image:secure_url" content={imageUrl ? imageUrl : ""} />
      <meta property="og:image:url" content={imageUrl ? imageUrl : ""} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content={width?width:"600"} />
      <meta property="og:image:height" content={height?height:"400"} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:site_name" content="haus & haus" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="hausandhaus.com" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={`${ogTitle} | haus & haus`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageUrl?imageUrl:""} />
      <meta name="twitter:url" content={currentUrl} />
      <meta name="robots" content={robots}></meta>
      <link rel="canonical" href={currentUrl}/>
      {children}
      </Helmet>
    </>
  )
}

export default Seo
